@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  height: 100%;
}
